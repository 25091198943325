var render = function () {
  var _vm$$v$product, _vm$$v;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.reRender,
    staticClass: "add-new-investor-form"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm._v("Add new " + _vm._s(_vm.isSubscription ? 'investor' : 'product'))]), _vm.investorId || _vm.investorChosen ? [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.productSelects.productType,
      "label": 'PRODUCT TYPE',
      "placeholder": 'Choose knowledge',
      "allow-empty": false,
      "validation": (_vm$$v$product = _vm.$v.product) === null || _vm$$v$product === void 0 ? void 0 : _vm$$v$product.type
    },
    on: {
      "input": _vm.setProductType
    },
    model: {
      value: _vm.product.type,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "type", $$v);
      },
      expression: "product.type"
    }
  })] : [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'SELECT INVESTOR',
      "options": _vm.investorOptions,
      "searchable": true,
      "addOptionBtn": true,
      "addOptionBtnText": "Add new investor",
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.selectedInvestor
    },
    on: {
      "addOption": _vm.handleAddOption
    },
    model: {
      value: _vm.selectedInvestor,
      callback: function callback($$v) {
        _vm.selectedInvestor = $$v;
      },
      expression: "selectedInvestor"
    }
  })], _c('sygni-rect-button', {
    staticClass: "filled gn-primary button secondary",
    on: {
      "click": _vm.goNext
    }
  }, [_vm._v("Next")]), _c('div', {
    staticClass: "cancel-button",
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Cancel")])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }